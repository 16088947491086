import { Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { FILTER_TYPE, IFilterRequest, IFilterResponse } from 'src/app/shared/filter/filter.interface';
import { NotificationHistoryResponse } from '../../interface/notification-history.interface';
import * as moment from 'moment';
import { NotificationHistoryService } from '../../services/notification-history.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from 'src/app/widgets/confirm-dialog/confirm-dialog.component';
import { take } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { NotificationHistoryActions } from '../../store/notification-history.actions';
import { UtilsService } from 'src/app/services/shared/utils.service';
import { Editor, Toolbar } from 'ngx-editor';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-notification-history-landing',
  templateUrl: './notification-history-landing.component.html',
  styleUrls: [
    './notification-history-landing.component.scss',
    '../../../notifications.scss'
  ],

  encapsulation: ViewEncapsulation.Emulated
})
export class NotificationHistoryLandingComponent implements OnInit, OnDestroy {
  form: FormGroup;
  characterCount: number = 0;
  maxCharacters: number = 1000;
  editor: Editor;
  toolbar: Toolbar = [
    ['bold', 'italic', 'underline'], // Text styling
    ['ordered_list', 'bullet_list'], // Lists
    ['align_left', 'align_center', 'align_right', 'align_justify'], // Text alignment
    ['text_color', 'background_color'], // Color text
    ['link'], // Links
  ];

  public minDate = new Date();

  public maxDate = new Date();

  filterInput: IFilterRequest;

  public showFilter: boolean = false;
  /**
   * Holds original response
   */
  public notificationsResponse: NotificationHistoryResponse[];

  /**
   * Displayed on UI
   */
  public filteredNotificationsResponse: NotificationHistoryResponse[];
  /**
   * Holds copy of filteredNotificationsResponse as we need to 
   */
  public _filteredNotificationsResponse: NotificationHistoryResponse[];

  public displaySendTimePicker: boolean = false;

  public hideSpinner: boolean = false;

  public displayErrorMessage: boolean = true;

  public minuteIntervalArr: number[] = [0, 15, 30, 45];

  public createdByUsersList: string[] = [];

  constructor(private fb: FormBuilder, private notificationHistoryService: NotificationHistoryService, private dialog: MatDialog, private utilsService: UtilsService,
    private store: Store) {
    this.maxDate.setDate(this.maxDate.getDate() + 14);
  }

  ngOnInit(): void {
    this.initializeValues();
    this.editor = new Editor();
    this.form = this.fb.group({
      content: ['']
    });
    this.form.get(['content'])?.valueChanges.subscribe(content => {
      this.onContentChange(content);
    });
  }
  onContentChange(content: any) {
    // Strip HTML tags and count characters
    const textContent = content.replace(/<[^>]+>/g, '');
    this.characterCount = textContent.length;
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  public initializeValues(): void {
    this.filterInput = {};
    this.getNotificationsHistory();
  }

  private getNotificationsHistory(): void {
    this.displayErrorMessage = false;
    this.notificationHistoryService.getNotificationsHistory()
      .subscribe((response: NotificationHistoryResponse[]) => {
        this.hideSpinner = true;
        this.notificationsResponse = response;
        this.sortNotificationHistoryResponse();
      }, () => {
        this.hideSpinner = true;
        this.displayErrorMessage = true;
      });
  }

  private sortNotificationHistoryResponse(): void {
    if (this.notificationsResponse?.length <= 0) {
      this.filteredNotificationsResponse = [];
      this._filteredNotificationsResponse = [];
      this.createdByUsersList = [];
      return;
    }
    const users: Set<string> = new Set();
    this.notificationsResponse?.forEach((notification: NotificationHistoryResponse) => {
      notification.message.createdBy = notification?.message?.name?.firstName ? `${notification?.message?.name?.firstName} ${notification?.message?.name?.lastName}` : 'NA';
      users.add(notification?.message?.createdBy);
      notification.scheduleDate = new Date(notification?.scheduleDateTime);
      notification.scheduleTime = moment(notification?.scheduleDateTime)?.format('HH:mm');
      if (notification?.message?.messageType === '1') {
        notification.message.msgTitle = null;
        notification.message.notificationBody = null;
      }
      if (notification?.message?.messageType === '2') {
        notification.message.alertBody = null;
      }
      this.updateCategory(notification);
    });
    this.createdByUsersList = Array.from(users).sort();
    this.notificationsResponse.sort((a, b) => (a?.category?.toLocaleLowerCase() > b.category?.toLocaleLowerCase()) ? 1 : ((b.category?.toLocaleLowerCase() > a.category?.toLocaleLowerCase()) ? -1 : 0));
    this.notificationsResponse.sort((a, b) => (a?.scheduleDateTime < b?.scheduleDateTime ? -1 : (a?.scheduleDateTime > b?.scheduleDateTime ? 1 : 0)));
    this.filteredNotificationsResponse = JSON.parse(JSON.stringify(this.notificationsResponse));
    this._filteredNotificationsResponse = JSON.parse(JSON.stringify(this.notificationsResponse));
  }

  public updateCategory(notification): void {
    notification.displayId = true;
    switch (notification?.category?.toLocaleLowerCase()) {
      case 'course':
        notification.category = 'Course';
        break;
      case 'meeting':
        notification.category = 'Meeting Room';
        break;
      case 'guest':
        notification.category = 'Guest';
        break;
      case 'program':
        notification.category = 'Program';
        break;
      case 'house':
        notification.displayId = false;
        notification.category = 'House and Well-Being Event';
        break;
      case 'session':
        notification.displayId = false;
        notification.category = 'Session';
        break;
    }
  }

  public appliedDateFilter(appliedFilter: IFilterResponse): void {
    const filteredResult = [];
    this.filterInput = appliedFilter;
    this.notificationsResponse.forEach((history: NotificationHistoryResponse) => {
      const scheduleDateTime: Date = new Date(history?.scheduleDateTime);
      scheduleDateTime.setHours(0, 0, 0, 0);
      appliedFilter.startDate = new Date(appliedFilter.startDate);
      appliedFilter.endDate = new Date(appliedFilter.endDate);
      appliedFilter.startDate.setHours(0, 0, 0, 0);
      appliedFilter.endDate.setHours(0, 0, 0, 0);
      if (appliedFilter?.filterType === FILTER_TYPE.CREATED_BY) {
        if ((history?.message?.createdBy === appliedFilter?.selectedAutoCompleteValue)) {
          filteredResult.push(history);
        }
      } else {
        if ((scheduleDateTime >= appliedFilter?.startDate && scheduleDateTime <= appliedFilter?.endDate)) {
          filteredResult.push(history);
        }
      }
    });
    this.filteredNotificationsResponse = JSON.parse(JSON.stringify(filteredResult));
    this._filteredNotificationsResponse = JSON.parse(JSON.stringify(filteredResult));
  }

  public resetToDefault(): void {
    this.filteredNotificationsResponse = JSON.parse(JSON.stringify(this.notificationsResponse));
    this._filteredNotificationsResponse = JSON.parse(JSON.stringify(this.notificationsResponse));
    this.filterInput = {};
  }

  public toggleRecipentsAttribute(index: number): void {
    this.getRecipentsAttributes(index);
    this.updateFilteredNotificationsResponse(index, 'showAttributes', !this.filteredNotificationsResponse[index].showAttributes);
  }

  private getRecipentsAttributes(index: number) {
    this.filteredNotificationsResponse[index].displaySpinner = true;
    const id = this.filteredNotificationsResponse[index]?.id;
    this.store.dispatch(new NotificationHistoryActions.GetRecipientsAttribute(id))
      .pipe(
        take(1)
      )
      .subscribe((response: any) => {
        const recipentsAttribute = response?.notifications?.recipientsAttributes[id];
        this.filteredNotificationsResponse[index].recipentsAttribute = recipentsAttribute;
        this.filteredNotificationsResponse[index].displaySpinner = false;
      }, () => {
        this.filteredNotificationsResponse[index].displaySpinner = false;
      });
  }

  public editNotification(index: number): void {
    this.filteredNotificationsResponse = this.notificationsResponse;
    const isFeatureEnabled = true;
    if (!isFeatureEnabled) {
      return;
    }
    this.filteredNotificationsResponse = this.filteredNotificationsResponse.map(x => {
      x.editMode = false;
      return x;
    })

    this.updateFilteredNotificationsResponse(index, 'editMode', !this.filteredNotificationsResponse[index].editMode);
    if (this.filteredNotificationsResponse[index].editMode) {
      this.filteredNotificationsResponse[index] = JSON.parse(JSON.stringify(this._filteredNotificationsResponse[index]));
    }
  }

  public editNotificationCancel(index: number): void {
    const isFeatureEnabled = true;
    if (!isFeatureEnabled) {
      return;
    }
    this.updateFilteredNotificationsResponse(index, 'editMode', !this.filteredNotificationsResponse[index].editMode);
    if (!this.filteredNotificationsResponse[index].editMode) {
      this.filteredNotificationsResponse[index] = JSON.parse(JSON.stringify(this._filteredNotificationsResponse[index]));
    }
  }

  public deleteNotification(index: number): void {
    this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "",
        body: 'Are you sure you want to delete notification',
        buttonTextNo: "Cancel",
        buttonTextYes: "Yes, delete it!"
      },
      panelClass: 'notification-submit-dialog'
    }).afterClosed()
      .pipe(take(1))
      .subscribe(result => {
        if (result) {
          this.hideSpinner = false;
          this.displayErrorMessage = false;
          this.notificationHistoryService.deleteNotification(this.filteredNotificationsResponse[index]?.id)
            .subscribe((response: NotificationHistoryResponse[]) => {
              this.hideSpinner = true;
              this.notificationsResponse = response;
              this.sortNotificationHistoryResponse();
            }, () => {
              this.hideSpinner = true;
              this.displayErrorMessage = true;
            });
        }
      });
  }

  public updateNotification(index: number): void {
    const newDate = moment(this.filteredNotificationsResponse[index]?.scheduleDate).format('YYYY-MM-DD');
    const selectedObject = this.filteredNotificationsResponse[index];
    const payload: NotificationHistoryResponse = {
      scheduleDateTime: `${newDate} ${this.filteredNotificationsResponse[index]?.scheduleTime}:00.0`,
      message: selectedObject?.message
    };
    switch (payload?.message?.messageType) {
      case '1':
        payload.message.msgTitle = '';
        payload.message.notificationBody = '';
        break;
      case '2':
        payload.message.alertBody = '';
        break;
    }
    this.hideSpinner = false;
    if (this.characterCount <= this.maxCharacters) {
      this.notificationHistoryService.updateNotification(payload, selectedObject?.id).subscribe((response: NotificationHistoryResponse[]) => {
        this.hideSpinner = true;
        this.notificationsResponse = response;
        this.sortNotificationHistoryResponse();
      }, (error: any) => {
        console.log(error);
        this.hideSpinner = true;
      });
    }
    else {
      this.hideSpinner = true;
      this.openAlertDialog(`Body has a maximum of ${this.maxCharacters} characters`);
    }
  }

  openAlertDialog(body: string): Observable<undefined> {
    return this.dialog.open(ConfirmDialogComponent, {
      data: {
        title: "",
        body: body,
        buttonTextNo: null,
        buttonTextYes: "Ok"
      },
      panelClass: 'notification-submit-dialog'
    }).afterClosed();
  }

  public addRemoveToExistingNotification(index: number, isAlert: boolean, remove: boolean) {
    this.updateMessageType(index, isAlert, remove);
    if (isAlert) {
      this.filteredNotificationsResponse[index].message.alertBody = remove ? null : this._filteredNotificationsResponse[index]?.message?.alertBody || '';
      return;
    }
    this.filteredNotificationsResponse[index].message.msgTitle = remove ? null : this._filteredNotificationsResponse[index].message.msgTitle || '';
    this.filteredNotificationsResponse[index].message.notificationBody = remove ? null : this._filteredNotificationsResponse[index].message.notificationBody || '';
  }

  private updateMessageType(index: number, isAlert: boolean, remove: boolean): void {
    if (remove) {
      this.filteredNotificationsResponse[index].message.messageType = isAlert ? '2' : '1';
    } else {
      this.filteredNotificationsResponse[index].message.messageType = '3';
    }
  }

  private updateFilteredNotificationsResponse(index: number, property: string, value: string | boolean): void {
    this.filteredNotificationsResponse[index][property] = value;
    this._filteredNotificationsResponse[index][property] = value;
  }

  sendTimeChange(event, index: number): void {
    this.filteredNotificationsResponse[index]['scheduleTime'] = event?.value;
    this.displaySendTimePicker = false;
    this.validateIfFutureDated(index);
  }

  public sendDateChange(index: number): void {
    this.validateIfFutureDated(index);
  }

  private validateIfFutureDated(index: number): void {
    if (this.filteredNotificationsResponse[index]['scheduleTime'] && this.filteredNotificationsResponse[index]['scheduleDate']) {
      const hoursAndMinutes: any[] = this.filteredNotificationsResponse[index]['scheduleTime'].split(':');
      const newDateTime: Date = new Date(this.filteredNotificationsResponse[index]['scheduleDate']);
      newDateTime.setHours(hoursAndMinutes[0], hoursAndMinutes[1]);
      const timeInEST = this.utilsService.changeTimeZone(new Date(), 'America/New_York');
      this.filteredNotificationsResponse[index].isNotFutureDated = newDateTime < timeInEST;
    }
  }

  wordCounter(event: KeyboardEvent) {

    const input = this.form.get('content');
    const truncatedContent = this.form.get('content')?.value?.replace(/<[^>]+>/g, '');

    if (input && truncatedContent.length >= this.maxCharacters && !this.isSpecialKey(event)) {
      event.preventDefault();
    }

  }

  private isSpecialKey(event: KeyboardEvent): boolean {

    const specialKeys = ["Backspace", "Delete", "ArrowRight", "ArrowLeft", "ArrowUp", "ArrowDown"];
    return specialKeys.indexOf(event.key) !== -1;

  }
}
