import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NotificationsService } from '../../../services/aws/notification/notificationservice';
import { ActivatedRoute } from '@angular/router';
import { Router } from '@angular/router';
import { IRecipient, ICategoryData, IAttribute } from '../../../models/INotifications';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { Observable, Subscription, of } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { ConfirmDialogComponent } from 'src/app/widgets/confirm-dialog/confirm-dialog.component';
import { AuthenticationService } from '../../../services/authentication/authentication.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { CATEGORY_DISPLAY_MAP } from '../../../consts/category-display-map';
import { ICategoryDisplay } from 'src/app/models/ICategoryDisplay';
import { customAlphabet } from 'nanoid';
import { KeyValue } from '@angular/common';
import { IDropdown } from 'src/app/widgets/dropdown/dropdown.interface';
import { API_USER_DEFINED_MESSAGE } from 'src/app/widgets/error-message/error-message.const';
import { IErrorMessage } from 'src/app/widgets/error-message/error-message.interface';
import { NotificationHistoryLandingComponent } from './notification-history/components/notification-history-landing/notification-history-landing.component';
import { UtilsService } from 'src/app/services/shared/utils.service';
import { Editor, Toolbar } from 'ngx-editor';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NavMenuService } from 'src/app/services/nav-menu/nav-menu.service';

const nanoId = customAlphabet('1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ', 15);

@Component({
    selector: 'newnotifications',
    templateUrl: './notifications.component.html',
    styleUrls: ['./notifications.scss']
})
export class NotificationsComponent implements OnDestroy, OnInit {
    form: FormGroup;
    characterCount: number = 0;
    maxCharacters: number = 1000;
    editor: Editor;
    toolbar: Toolbar = [
        ['bold', 'italic', 'underline'], // Text styling
        ['ordered_list', 'bullet_list'], // Lists
        ['align_left', 'align_center', 'align_right', 'align_justify'], // Text alignment
        ['text_color', 'background_color'], // Color text
        ['link'], // Links
      ];
 
    public minDate = new Date();
    public maxDate = new Date();
    /**
     * Holds selected recipient attributes
     */
    public selectedReceipent: Map<string, ICategoryData> = new Map();

    /**
     * Holds recipients
     */
    public recipients: IRecipient[] = [];

    public selectedCategoriesCount: number = 0;
    /**
     * Holds API response for selected message category
     */
    public categories: Array<ICategoryData> = [];

    /**
     * Holds comma delimited list of all names of selected category items
     */
    public selectedCategoryItemNames: object[];

    /**
     * Holds boolean and set to true when filter is applied to display categories section
     */
    public showCategoriesSection: boolean = false;
    /**
     * Holds boolean and set to true if we want to show global spinner
     */
    public showGlobalSpinner: boolean = false;

    /**
     * Holds boolean to sort by name
     */
    public isNameAsc: boolean = true;

    /**
     * Holds boolean to sort by name
     */
    public isEmailAsc: boolean = false;
    public isTypeAsc: boolean = false;

    /**
     * Holds the value of selected column to sort
     */
    public columnSelectedForSorting: string;

    public isDesc: boolean = false;
    public direction: number;

    /**
     * Holds error message if categories API failed or no catrgories available
     */
    public categoriesErrorMessage: string;

    /**
     * Holds boolean ans set to true if we want to hide Recipent section on right panel
     */
    public hideRecipientSection: boolean = true;

    /**
     * Holds type of type that was selected. EX: When, At, After, Range
     */
    public selectedTimeType: string = '';
    /**
     * Holds type of type that was selected. EX: When, At, After, Range when the user selects start and end type
     */
    public selectedAndTimeType: string = '';
    /**
     * Holds boolean to determine of start date is selected or not in filter
     */
    public startOrEndTime: string;

    public selectedAndStartTime: string;

    public selectedEndTime: string = '00:00';

    public selectedAndEndTime: string = '00:00';

    public isFutureDated: boolean = true;

    @ViewChild('notificationHistory') notificationHistory: NotificationHistoryLandingComponent;


    public startAndEndTimeDropdownValues: IDropdown[] = [
        {
            displayText: 'Start Time',
            value: 'start'
        },
        {
            displayText: 'End Time',
            value: 'end'
        },
        {
            displayText: 'Start and End Time',
            value: 'startAndEnd'
        }];

    public startDateRange: IDropdown[] = [
        {
            displayText: 'Before',
            value: 'before'
        },
        {
            displayText: 'At',
            value: 'at'
        },
        {
            displayText: 'After',
            value: 'after'
        },
        {
            displayText: 'Range',
            value: 'range'
        }];

    public displayFromTimePicker: boolean = false;
    public displaySendTimePicker: boolean = false;
    public displayAndFromTimePicker: boolean = false;
    public displayToTimePicker: boolean = false;
    public displayAndToTimePicker: boolean = false;
    public selectedStartTime: string;

    hideHistoryRecipientSection: boolean = false;
    /**
     * Holds boolean to enable or disable time selection in filter panel
     */
    public disableTimeSelection: boolean = true;

    /**
      * Holds boolean to enable or disable time selection in filter panel
      */
    public disableTimeSelectionCheckBox: boolean = true;

    /**
     * Holds boolean to display to range
     */
    public displayToRange: boolean = false;

    public displayAndToRange: boolean = false;
    public showSpecificDateControls: boolean = false;
    public sendCategory: any;
    public category: any;
    public categoryDescription: string = '';
    public categorySelectName: string = '';

    minuteIntervalArr: number[] = [0, 15, 30, 45];
    sendDate: Date;
    sendTime;
    // cats$: Observable<Array<ICategoryData>>
    words: number;
    wordCount: string = '0';
    titleWordCount: string = '0';
    bodyWordCount: string = '0';
    text: any;
    mode: string = 'view';
    searchmode: string = 'open';
    recipients$: Observable<IRecipient>;
    isRecipient: boolean = false;
    isAlert: boolean = false;
    alertBody: string = '';
    isAnnouncement: boolean = false;
    isCatList: boolean = false;

    participantsarray: string[];
    isRecipeintAttributes: boolean = false;
    selectedCategory: string = 'Program';
    categoryValue: string = '';
    isParticipantSelected: boolean;
    isClassSelected: boolean;
    isDisplay: boolean = true;
    showLoading: boolean = false;
    checkAll: any;
    isSaved: boolean = false;
    recipientsCount: number;
    recipientCount = '';
    textareas: any;
    messageType: string = '';
    participants: string = "";
    vals: any;
    isSuccess: boolean = false;
    isError: boolean = false;
    recipientErrorMessage: IErrorMessage;
    dtStart: string;
    dtEnd: string;
    name: string = '';
    recipientAttributes: string = '';
    isRecipientCount: boolean = false;
    recdata: ICategoryData;
    selectedAttributes$: Observable<any>;
    selectedAttributes: IAttribute;
    test$: Observable<any>;
    test2$: Observable<IAttribute>;
    attributes: any;
    testname: any;
    participantsArray: number[];
    filter: string;
    displayFilter: boolean = true;
    isFilter: boolean = false;
    isFilterActive: boolean = false;
    isSelectCategory: boolean = false;
    isCategorySelected: boolean = false;
    filterType: string = '';
    announceBody: string;
    announcementTitle: string = '';
    announcementMessage: string = '';
    filterName1: string;
    filterName2: string;
    filterName3: string;
    filterTodayChecked: boolean;
    filterOnChecked: boolean;
    isFilterOnValid: boolean = true;
    filterFromChecked: boolean;
    isFilterFromStartValid: boolean = true;
    isFilterFromEndValid: boolean = true;
    isFilterApplyEnabled: boolean = false;
    subscriptions: Subscription[] = [];
    email: string = '';

    breadcrumbOptions = {
        // dropdowns: [],
        breadcrumb: [
            {
                label: 'Dashboard',
                itemclass: 'Breadcrumb-item',
                linkclass: 'Breadcrumb-link',
                link: '/admin/dashboard'
            },
            {
                label: 'Ad-Hoc Notifications',
                itemclass: 'Breadcrumb-item',
                linkclass: 'Breadcrumb-link',
                link: '/admin/settings/notification'
            }
        ],
        // toggle: {}
    };

    constructor(private fb: FormBuilder, private notificationService: NotificationsService, private route: ActivatedRoute, private dialog: MatDialog, private router: Router, private authenticationService: AuthenticationService,
        private matIconRegistry: MatIconRegistry, private utilsService: UtilsService,
        private domSanitizer: DomSanitizer,private navMenuService: NavMenuService) {
        this.matIconRegistry.addSvgIcon(
            "timeTable",
            this.domSanitizer.bypassSecurityTrustResourceUrl("../../../../assets/images/time-table.svg")
        );
        this.maxDate.setDate(this.maxDate.getDate() + 14);
    }

    ngOnInit(): void {
        this.editor = new Editor();
        this.form = this.fb.group({
            content: ['']
        });
        this.form.get(['content'])?.valueChanges.subscribe(content => {
            this.onContentChange(content);
        });
    }

    onContentChange(content: string): void {
        // Strip HTML tags and count characters
        const textContent = content.replace(/<[^>]+>/g, '');
        this.characterCount = textContent.length;
    }


    private initializeValues(): void {
        this.minDate = new Date();
        this.maxDate = new Date();
        this.maxDate.setDate(this.maxDate.getDate() + 14);
        this.selectedReceipent = new Map();
        this.recipients = [];
        this.selectedCategoriesCount = 0;
        this.categories = [];
        this.selectedCategoryItemNames = [];
        this.showCategoriesSection = false;
        this.showGlobalSpinner = false;
        this.isNameAsc = true;
        this.isEmailAsc = false;
        this.isTypeAsc = false;
        this.columnSelectedForSorting;
        this.isDesc = false;
        this.direction = null;
        this.categoriesErrorMessage = null;
        this.hideRecipientSection = true;
        this.selectedTimeType = '';
        this.selectedAndTimeType = '';
        this.startOrEndTime = null;
        this.selectedAndStartTime = null;
        this.selectedEndTime = '00:00';
        this.selectedAndEndTime = '00:00';
        this.isFutureDated = true;
        this.displayFromTimePicker = false;
        this.displaySendTimePicker = false;
        this.displayAndFromTimePicker = false;
        this.displayToTimePicker = false;
        this.displayAndToTimePicker = false;
        this.selectedStartTime = null;
        this.hideHistoryRecipientSection = false;
        this.disableTimeSelection = true;
        this.disableTimeSelectionCheckBox = true;
        this.displayToRange = false;
        this.displayAndToRange = false;
        this.showSpecificDateControls = false;
        this.sendCategory = null;
        this.category = null;
        this.categoryDescription = '';
        this.categorySelectName = '';
        // this.minuteIntervalArr = [0, 15, 30, 45];
        this.sendDate = null;
        this.sendTime = null;
        this.words = null;
        this.wordCount = '0';
        this.titleWordCount = '0';
        this.bodyWordCount = '0';
        this.text = null;
        this.mode = 'view';
        this.searchmode = 'open';
        this.recipients$ = null;
        this.isRecipient = false;
        this.isAlert = false;
        this.alertBody = '';
        this.isAnnouncement = false;
        this.isCatList = false;
        this.participantsarray = null;
        this.isRecipeintAttributes = false;
        this.selectedCategory = 'Program';
        this.categoryValue = '';
        this.isParticipantSelected = null;
        this.isClassSelected = null;
        this.isDisplay = true;
        this.showLoading = false;
        this.checkAll = null;
        this.isSaved = false;
        this.recipientsCount = null;
        this.recipientCount = '';
        this.textareas = null;
        this.messageType = '';
        this.participants = "";
        this.vals = null;
        this.isSuccess = false;
        this.isError = false;
        this.recipientErrorMessage = null;
        this.dtStart = null;
        this.dtEnd = null;
        this.name = '';
        this.recipientAttributes = '';
        this.isRecipientCount = false;
        this.recdata = null;
        this.selectedAttributes$ = null;
        this.selectedAttributes = null;
        this.test$ = null;
        this.test2$ = null;
        this.attributes = null;
        this.testname = null;
        this.participantsArray = null;
        this.filter = null;
        this.displayFilter = true;
        this.isFilter = false;
        this.isFilterActive = false;
        this.isSelectCategory = false;
        this.isCategorySelected = false;
        this.filterType = '';
        this.announceBody = null;
        this.announcementTitle = '';
        this.announcementMessage = '';
        this.filterName1 = null;
        this.filterName2 = null;
        this.filterName3 = null;
        this.filterTodayChecked = null;
        this.filterOnChecked = null;
        this.isFilterOnValid = true;
        this.filterFromChecked = null;
        this.isFilterFromStartValid = true;
        this.isFilterFromEndValid = true;
        this.isFilterApplyEnabled = false;
        this.subscriptions = [];
        this.email = '';
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.editor.destroy();
    }

    addMessageType($event) {
        switch ($event.srcElement.value) {
            case '1':
                this.isAnnouncement = false;
                this.isAlert = true;
                this.messageType = '1';
                this.isSelectCategory = true;
                break;
            case '2':
                this.isAnnouncement = true;
                this.isAlert = false;
                this.messageType = '2';
                this.isSelectCategory = true;
                break;
            case '3':
                this.isAnnouncement = true;
                this.isAlert = true;
                this.messageType = '3';
                this.isSelectCategory = true;
        }
    }

    /**
     * Function used to update if the user selected start or end time
     * @param event
     */
    public startOrEndTimeSelected(event: string): void {
        this.startOrEndTime = event;
    }

    /**
     * Function used to show hide to range
     * @param event
     */
    public timeTypeSelected(event: string): void {
        this.selectedTimeType = event;
        this.displayToRange = event === 'range' ? true : false;
    }

    /**
     * Function used to show hide to range for and selection
     * @param event 
     */
    public andTimeTypeSelected(event: string): void {
        this.selectedAndTimeType = event;
        this.displayAndToRange = event === 'range' ? true : false;
    }

    truncate(val, limit) {
        if (val.length > limit) {
            return val.substring(0, limit);
        }
        else {
            return val;
        }
    }

    /**
     * 
     * @param $event Function used to store selected category
     */
    public selectCategory($event): void {
        this.clearAllFilters();
        $event.target.checked = true;
        this.isCategorySelected = true;
        this.recipients = [];
        let categoryDisplay: ICategoryDisplay = CATEGORY_DISPLAY_MAP.get($event.srcElement.value);
        this.category = categoryDisplay.name;
        this.categoryDescription = categoryDisplay.description;
        this.categorySelectName = categoryDisplay.selectName;
    }

    wordCounter(event: KeyboardEvent) {
        if (event) {
            const input = this.form.get('content');
            const truncatedContent = this.form.get('content')?.value?.replace(/<[^>]+>/g, '');

            if (input && truncatedContent.length >= this.maxCharacters && !this.isSpecialKey(event)) {
                event.preventDefault();
            }
        }
        this.wordCount = String(this.alertBody.length);
        this.titleWordCount = String(this.announcementTitle.length);
        this.bodyWordCount = String(this.announcementMessage.length);

    }

    private isSpecialKey(event: KeyboardEvent): boolean {

        const specialKeys = ["Backspace", "Delete", "ArrowRight", "ArrowLeft", "ArrowUp", "ArrowDown"];
        return specialKeys.indexOf(event.key) !== -1;

    }

    showFilter() {
        this.displayFilter = !this.displayFilter;
    }

    clearFilter() {
        this.isFilter = false;
        this.isFilterActive = false;
        this.isFilterApplyEnabled = false;
        this.isFilterFromStartValid = true;
        this.isFilterFromEndValid = true;
        this.isFilterOnValid = true;
        this.filterTodayChecked = false;
        this.filterOnChecked = false;
        this.filterFromChecked = this.displayToRange = this.displayAndToRange = false;
        this.disableTimeSelection = true;
        this.disableTimeSelectionCheckBox = true;
        this.startOrEndTime = this.selectedTimeType = this.selectedAndTimeType = '';
        this.selectedStartTime = this.selectedEndTime = this.selectedAndStartTime = this.selectedAndEndTime = '00:00';
        this.filterName1 = 'Date';
        this.filterName2 = 'Start Date';
        this.filterName3 = 'End Date';
        this.showCategoriesSection = false;
        this.selectedCategoriesCount = 0;
    }

    processFilter($event) {
        this.filterTodayChecked = false;
        this.filterFromChecked = false;
        this.filterOnChecked = false;
        this.disableTimeSelectionCheckBox = false;
        this.isFilterApplyEnabled = false;
        this.isFilter = true;
        switch ($event.srcElement.value) {
            case '':
                this.isFilter = false;
                break;
            case 'today':
                this.filterTodayChecked = true;
                this.isFilterApplyEnabled = true;
                this.filterType = 'today';
                this.filterName1 = 'Date';
                this.filterName2 = 'Start Date';
                this.filterName3 = 'End Date';
                this.isFilterOnValid = true;
                this.isFilterFromStartValid = true;
                this.isFilterFromEndValid = true;
                break;
            case 'date':
                this.filterOnChecked = true;
                this.filterType = 'date';
                this.filterName2 = 'Start Date';
                this.filterName3 = 'End Date';
                this.isFilterFromStartValid = true;
                this.isFilterFromEndValid = true;
                break;
            case 'from':
                this.filterName1 = 'Date';
                this.filterFromChecked = true;
                this.filterType = 'from';
                this.disableTimeSelectionCheckBox = true;
                this.isFilterOnValid = true;
                break;
        }
    }
    /**
     * Function used to enable disable dropdown and input field to enter time range in filter
     * @param event
     */
    public enableOrDisableTimeSelection(event): void {
        this.disableTimeSelection = !event.srcElement.checked;
    }

    public onFilterDateChanged(value, field) {
        const selectedDate = moment(value);
        const isDateValid = (selectedDate.isSameOrAfter(moment().subtract(7, "days")));
        switch (field) {
            case 'on':
                this.isFilterOnValid = isDateValid;
                this.isFilterApplyEnabled = isDateValid;
                break;
            case 'from-start':
            case 'from-end':
                if (field === 'from-start') {
                    this.isFilterFromStartValid = isDateValid;
                } else {
                    this.isFilterFromEndValid = isDateValid;
                }
                const startDate = this.filterName2 !== 'Start Date';
                const endDate = this.filterName3 !== 'End Date';
                this.isFilterApplyEnabled = !!(startDate && endDate) && this.isFilterFromEndValid && this.isFilterFromStartValid;
                break;
        }
    }

    indexOrderAsc = (a: KeyValue<string, ICategoryData>, b: KeyValue<string, ICategoryData>): number => {
        return a.value.name.localeCompare(b.value.name, undefined, { numeric: true, sensitivity: 'base' });
    };

    runFilter() {
        if (!this.isFilterApplyEnabled) {
            return;
        }
        let filter: Date = null;
        this.clearCategoriesSection();
        if (this.isFilter) {
            this.isFilterActive = true;
            this.selectedCategoriesCount = 0;
            let startDate = '';
            let endDate = '';
            let startDateStart: string = '';
            let startDateEnd: string = '';
            let endDateStart: string = '';
            let endDateEnd: string = '';
            const startAndEndTime: any = this.processTimeFilter();
            switch (this.filterType) {
                case 'today':
                    filter = new Date();
                    var dd = String(filter.getDate()).padStart(2, '0');
                    var mm = String(filter.getMonth() + 1).padStart(2, '0'); //January is 0!
                    var yyyy = filter.getFullYear();
                    startDate = yyyy + '-' + mm + '-' + dd;
                    endDate = yyyy + '-' + mm + '-' + dd;
                    ({ startDateStart, startDateEnd, endDateStart, endDateEnd } = this.getStartAndEndDate(startDate, endDate));
                    this.dtStart = `${startDateStart} ${startAndEndTime?.startTimeStart},${startDateEnd} ${startAndEndTime?.startTimeEnd}`;
                    this.dtEnd = `${endDateStart} ${startAndEndTime?.endTimeStart},${endDateEnd} ${startAndEndTime?.endTimeEnd}`;
                    if (this.category === 'course') {
                        this.dtStart = `${startDateStart} ${startAndEndTime?.startTimeStart},${startDateEnd} ${startAndEndTime?.startTimeEnd}`;
                        this.dtEnd = `${endDateStart} ${startAndEndTime?.endTimeStart},${endDateEnd} ${startAndEndTime?.endTimeEnd}`;
                    } else {
                        this.dtStart = startDate;
                        this.dtEnd = startDate;
                    }
                    break;
                case 'date':
                    startDate = (document.getElementById('dThisdate') as HTMLInputElement).value;
                    endDate = (document.getElementById('dThisdate') as HTMLInputElement).value;
                    if (this.dtStart == "Date" || this.dtStart == "") {
                        return;
                    }
                    ({ startDateStart, startDateEnd, endDateStart, endDateEnd } = this.getStartAndEndDate(startDate, endDate));
                    this.dtStart = `${startDateStart} ${startAndEndTime?.startTimeStart},${startDateEnd} ${startAndEndTime?.startTimeEnd}`;
                    this.dtEnd = `${endDateStart} ${startAndEndTime?.endTimeStart},${endDateEnd} ${startAndEndTime?.endTimeEnd}`;

                    if (this.category === 'course') {
                        this.dtStart = `${startDateStart} ${startAndEndTime?.startTimeStart},${startDateEnd} ${startAndEndTime?.startTimeEnd}`;
                        this.dtEnd = `${endDateStart} ${startAndEndTime?.endTimeStart},${endDateEnd} ${startAndEndTime?.endTimeEnd}`;
                    } else {
                        this.dtStart = startDate;
                        this.dtEnd = startDate;
                    }

                    break;
                case 'from':
                    startDate = (document.getElementById('dtStart') as HTMLInputElement).value;
                    endDate = (document.getElementById('dtEnd') as HTMLInputElement).value;
                    if (this.dtStart == "Start Date" || this.dtStart == "" || this.dtEnd == "End Date" || this.dtEnd == "") {
                        return;
                    }
                    if (this.category === 'course') {
                        this.dtStart = `${startDate} ${startAndEndTime?.startTimeStart},${endDate} ${startAndEndTime?.startTimeEnd}`;
                        this.dtEnd = `${startDate} ${startAndEndTime?.endTimeStart},${endDate} ${startAndEndTime?.endTimeEnd}`;
                    } else {
                        this.dtStart = startDate;
                        this.dtEnd = endDate;
                    }
                    break;
            }

            this.getCategoryFilterValues();
        } else {
            this.isFilterActive = false;
            this.getCategoryValues();
        }
        this.displayFilter = false;
        this.showCategoriesSection = true;
    }

    private getStartAndEndDate(startDate: string, endDate: string) {
        const startAndEndDate = {
            startDateStart: startDate,
            startDateEnd: startDate,
            endDateStart: startDate,
            endDateEnd: endDate
        };
        if (!(!this.startOrEndTime || this.disableTimeSelection)) {
            if (this.startOrEndTime === 'start') {
                startAndEndDate.endDateEnd = this.addSubtractYearsFromNow(startDate, 25);
            } else if (this.startOrEndTime === 'end') {
                startAndEndDate.startDateStart = this.addSubtractYearsFromNow(startDate, -25);

            }
        }
        return startAndEndDate;
    }

    /**
     * Function used to add years for given date
     * @param date 
     * @param addYearsFromNow  
     * @returns 
     */
    private addSubtractYearsFromNow(date: string, addYearsFromNow: number): string {
        let tempEndDate = new Date(date);
        const newYear = tempEndDate.getFullYear() + addYearsFromNow;
        tempEndDate.setFullYear(newYear);
        return tempEndDate.toISOString().substr(0, 10);
    }

    /**
     * Function used to update from time range
     * @param event 
     */
    updateFromTime(event): void {
        this.selectedStartTime = event?.value;
        this.displayFromTimePicker = false;
    }

    /**
     * Function used to update from time range
     * @param event 
     */
    updateAndFromTime(event): void {
        this.selectedAndStartTime = event?.value;
        this.displayAndFromTimePicker = false;
    }


    /**
     * Function used to update from time range
     * @param event 
     */
    updateToTime(event): void {
        this.selectedEndTime = event?.value;
        this.displayToTimePicker = false;
    }

    /**
     * Function used to update from time range
     * @param event 
     */
    updateAndToTime(event): void {
        this.selectedAndEndTime = event?.value;
        this.displayAndToTimePicker = false;
    }
    /**
     * Function used to process calculate start time and end time range
     */
    private processTimeFilter(): object {
        let startTimeStart = '00:00:00.0';
        let startTimeEnd = '23:59:00.0';
        let endTimeStart = '00:00:00.0';
        let endTimeEnd = '23:59:00.0';

        if ((this.filterType === 'today' || this.filterType === 'date') && !(!this.startOrEndTime || this.disableTimeSelection)) {
            if (this.startOrEndTime === 'start' || this.startOrEndTime === 'startAndEnd') {
                switch (this.selectedTimeType) {
                    case 'before':
                        const newBeforeTime = this.addSecondsToTime(this.selectedStartTime, -1);
                        startTimeEnd = `${newBeforeTime}.0` || startTimeEnd;
                        break;
                    case 'at':
                        startTimeStart = `${this.selectedStartTime}:00.0` || startTimeStart;
                        startTimeEnd = `${this.selectedStartTime}:00.0` || startTimeEnd;
                        break;
                    case 'after':
                        const newAfterTime = this.addSecondsToTime(this.selectedStartTime, 1);
                        startTimeStart = `${newAfterTime}.0` || startTimeStart;
                        break;
                    case 'range':
                        startTimeStart = `${this.selectedStartTime}:00.0` || startTimeStart;
                        startTimeEnd = `${this.selectedEndTime}:00.0` || startTimeEnd;
                        break;
                    default:

                }

            }

            if (this.startOrEndTime === 'end' || this.startOrEndTime === 'startAndEnd') {
                let startTime = this.selectedStartTime;
                let endTime = this.selectedEndTime;
                let type = this.selectedTimeType;
                if (this.startOrEndTime === 'startAndEnd') {
                    startTime = this.selectedAndStartTime;
                    endTime = this.selectedAndEndTime;
                    type = this.selectedAndTimeType;
                }
                switch (type) {
                    case 'before':
                        const newBeforeTime = this.addSecondsToTime(startTime, -1);
                        endTimeEnd = `${newBeforeTime}.0` || endTimeEnd;
                        break;
                    case 'at':
                        endTimeStart = `${startTime}:00.0` || endTimeStart;
                        endTimeEnd = `${startTime}:00.0` || endTimeEnd;
                        break;
                    case 'after':
                        const newAfterTime = this.addSecondsToTime(startTime, 1);
                        endTimeStart = `${newAfterTime}.0` || endTimeStart;
                        break;
                    case 'range':
                        endTimeStart = `${startTime}:00.0` || endTimeStart;
                        endTimeEnd = `${endTime}:00.0` || endTimeEnd;
                        break;
                    default:

                }
            }
        }
        return {
            startTimeStart,
            startTimeEnd,
            endTimeStart,
            endTimeEnd
        };
    }

    /**
     * Function used to modify time
     * @param time 
     * @param addBy 
     */
    private addSecondsToTime(time: string, addBy: number): string {
        const dummyDateAndTime = new Date(`02/13/2020 ${time}`); // We need to subtract 1 second. To make it simple creating dummy date
        const _addBy = time === '00:00' ? 0 : addBy;
        dummyDateAndTime.setSeconds(dummyDateAndTime.getSeconds() + _addBy);
        return dummyDateAndTime.toLocaleTimeString('it-IT');
    }

    dateFilter() {
        //Show Filter for certain options only
    }

    validateRecipients() {
        this.vals = document.getElementsByName('participant');
        var recpNum = 0;

        for (var i = 0, n = this.vals.length; i < n; i++) {
            if (this.vals[i].checked) {
                recpNum++;
            }
        }
        if (recpNum == 0) {
            return false;
        } else {
            if (recpNum > 0) {
                return true;
            }
        }
    }

    confirmsave() {

        if (this.isAnnouncement && this.isAlert) {
            if (this.announcementTitle == '' || this.announcementMessage == '' || this.alertBody == '' || !this.sendCategory) {
                this.openAlertDialog('You must complete all the fields');
                return;
            }
            if (this.sendCategory === "schedule" && (this.sendDate === undefined || this.sendTime === undefined)) {
                this.openAlertDialog('you must select date & time fields');
                return;
            }
            if (!this.validateRecipients()) {
                this.openAlertDialog('You must select at least one recipient.');
                return;
            }
            if (!this.isFutureDated) {
                this.openAlertDialog('Please select a future date and/or time');
                return;
            }
            if (this.announcementMessage?.replace(/<[^>]+>/g, '')?.length > this.maxCharacters) {
                this.openAlertDialog(`Body has a maximum of ${this.maxCharacters} characters`); // alert and announcemnets category(3) check
                return;
            }
            this.openConfirmDialog('Are you sure you wish to send this message? Both alert and announcement will be sent to recipients.')
                .pipe(take(1))
                .subscribe(result => {
                    if (result) {
                        this.save();
                    }
                });
        } else if (!this.isAnnouncement && this.isAlert) {
            if (this.alertBody == '' || !this.sendCategory) {
                this.openAlertDialog('You must complete all the fields');
                return;
            }
            if (this.sendCategory === "schedule" && (this.sendDate === undefined || this.sendTime === undefined)) {
                this.openAlertDialog('you must select date & time fields');
                return;
            }
            if (!this.validateRecipients()) {
                this.openAlertDialog('You must select at least one recipient.');
                return;
            }
            if (!this.isFutureDated) {
                this.openAlertDialog('Please select a future date and/or time');
                return;
            }
            this.openConfirmDialog("Are you sure you wish to send this message? An alert will be sent to recipients.")
                .pipe(take(1))
                .subscribe(result => {
                    if (result) {
                        this.save();
                    }
                });
        } else if (this.isAnnouncement && !this.isAlert) {
            if (this.announcementTitle == '' || this.announcementMessage == '' || !this.isRecipient || !this.sendCategory) {
                this.openAlertDialog('You must complete all the fields');
                return;
            } else if (this.announcementTitle.length > 50) {
                this.openAlertDialog('Title has a maximum of 50 characters');
                return;
            } else if (this.announcementMessage?.replace(/<[^>]+>/g, '')?.length > this.maxCharacters) {
                this.openAlertDialog(`Body has a maximum of ${this.maxCharacters} characters`);
                return;
            } else if (this.sendCategory === "schedule" && (this.sendDate === undefined || this.sendTime === undefined)) {
                this.openAlertDialog('you must select date & time fields');
                return;
            } else if (!this.validateRecipients()) {
                this.openAlertDialog('You must select at least one recipient.');
                return;
            }
            if (!this.isFutureDated) {
                this.openAlertDialog('Please select a future date and/or time');
                return;
            }
            this.openConfirmDialog("Are you sure you wish to send this message? An announcement will be sent to recipients.")
                .pipe(take(1))
                .subscribe(result => {
                    if (result) {
                        this.save();
                    }
                });
        }
    }

    getCategoryValues() {
        this.notificationService.getCategoryValues(this.category).pipe(
            map(data => this.parseCategoryValues(data)), take(1)
        ).subscribe((response: Array<ICategoryData>) => {
            if (!response.length) {
                this.categoriesErrorMessage = `No ${this.categorySelectName}s to display`;
                this.categories = [];
            } else {
                const selectAllObj: ICategoryData = {
                    name: 'Select All',
                    id: 'selectAll',
                    dtStart: '',
                    dtEnd: '',
                    email: '',
                    display: '',
                    dtStartTime: '',
                    dtEndTime: ''
                };
                response.splice(0, 0, selectAllObj);
                this.categories = response;
                this.checkIfCategoryAlreadySelected();
            }
        }, () => {
            this.categoriesErrorMessage = API_USER_DEFINED_MESSAGE.FAILED;
        });
    }

    getCategoryFilterValues() {
        this.notificationService.getCategoryFilterValues(this.category, this.dtStart, this.dtEnd).pipe(
            map(data => this.parseCategoryValues(data)), take(1)
        ).subscribe((response: Array<ICategoryData>) => {
            if (!response.length) {
                this.categoriesErrorMessage = `No ${this.categorySelectName}s to display`;
                this.categories = [];
            } else {
                const selectAllObj: ICategoryData = {
                    name: 'Select All',
                    id: 'selectAll',
                    dtStart: '',
                    dtEnd: '',
                    email: '',
                    display: '',
                    dtStartTime: '',
                    dtEndTime: ''
                };
                response.splice(0, 0, selectAllObj);
                this.categories = response;
                this.checkIfCategoryAlreadySelected();
            }

        },
            () => {
                this.categoriesErrorMessage = 'Something went wrong while processing your request';
            });
    }

    /**
     * Function used to pre-select the check box if already selected
     */
    private checkIfCategoryAlreadySelected(): void {
        this.categories.forEach((category: ICategoryData, index: number) => {
            if (this.selectedReceipent.has(`${category?.name?.toLocaleLowerCase()}-${category?._id}`)) {
                ++this.selectedCategoriesCount;
                setTimeout(() => {
                    this.categories[index].checked = true;
                }, 0);
            }
        });
        this.categories[0].checked = this.selectedCategoriesCount === this.categories.length - 1;
    }

    compareCategoryAlphaAscend(a, b) {
        return a.name.localeCompare(b.name, undefined, { numeric: true, sensitivity: 'base' });
    }

    private parseCategoryValues(categoryValues: Array<ICategoryData>): Array<ICategoryData> {
        categoryValues.sort(this.compareCategoryAlphaAscend);
        categoryValues = this.formatDatesForCategories(categoryValues);
        return this.generateDisplayForCategories(categoryValues);
    }

    private convertDateTimeToDate(dateText: string): string {
        return moment(dateText).format("MM/DD/YYYY");
    }

    private convertDateTimeToTime(dateText: string): string {
        return moment(dateText).format('hh:mm A');
    }

    private formatDatesForCategories(categories: Array<ICategoryData>): Array<ICategoryData> {
        categories.forEach((category: ICategoryData) => {
            if (category.dtStart) {
                category.dtStartTime = this.convertDateTimeToTime(category.dtStart);
                category.dtStart = this.convertDateTimeToDate(category.dtStart);
            }
            if (category.dtEnd) {
                category.dtEndTime = this.convertDateTimeToTime(category.dtEnd);
                category.dtEnd = this.convertDateTimeToDate(category.dtEnd);
            }
        });
        return categories;
    }

    private generateDisplayForCategories(categories: Array<ICategoryData>): Array<ICategoryData> {
        const existingKeys = Array.from(this.selectedReceipent.keys());

        categories.forEach((category: ICategoryData, index: number) => {
            category._id = `${index}${nanoId()}`;
            const matchingKeys = existingKeys.filter(key => key.includes(`${category?.name}-`?.toLocaleLowerCase()));
            if (matchingKeys?.length) {
                matchingKeys.forEach((key: string) => {
                    if (this.selectedReceipent.get(key)?.id === category?.id) {
                        category._id = this.selectedReceipent.get(key)?._id;
                    }
                });
            }
            switch (this.category) {
                case 'program':
                case 'facilitator':
                    category.display = `${category.dtStart} - ${category.dtEnd}`;
                    break;
                case 'course':
                    category.display = `${category.dtStart} • ${category.dtStartTime} - ${category.dtEndTime} • ${category.id}`;
                    break;
                case 'meeting':
                    category.display = "";
                    break;
                case 'session':
                    category.display = `${category?.dtStart || '(empty)'} • ${category?.dtStartTime || '(empty)'} - ${category?.dtEndTime || '(empty)'}`;
                    break;
                case 'house':
                    category.display = `${category?.dtStart || '(empty)'} • ${category?.dtStartTime || '(empty)'} - ${category?.dtEndTime || '(empty)'}`;
                    break;
                case 'guest':
                    category.display = "";
                    break;
            }
        });
        return categories;
    }

    getRecipientAttributeList() {
        var str = "";
        let vals = new Array();
        let id = '';
        let ids = new Array();
        this.attributes = document.getElementsByName('attribute');
        for (var i = 0, n = this.attributes.length; i < n; i++) {
            if (this.attributes[i].checked) {
                id = this.attributes[i].value;
                str = this.attributes[i].id;
                ids = str.split('*');
                vals.push(new IAttribute(ids[0], id, ids[1], ids[2], ids[3], ids[4]));
            }
        }
        this.selectedAttributes$ = of(vals);
        this.subscriptions.push(this.authenticationService.getUserEmail().pipe(take(1)).subscribe(res => {
            this.email = res as string;
        }
        ));
    }

    processRecipients() {
        this.vals = document.getElementsByName('participant');
        let str = "";
        for (var i = 0, n = this.vals.length; i < n; i++) {
            if (this.vals[i].checked) {
                if (i != n - 1) {
                    str = str.concat(this.vals[i].value, ",");
                } else {
                    str = str.concat(this.vals[i].value);
                }
            }
        }
        str = str.replace(/,(\s+)?$/, '');
        this.participants = str;
        this.participantsarray = str.split(',');
    }

    getRecipientAttributes() {
        let str = '';
        this.selectedReceipent.forEach((category: ICategoryData) => {
            str = str.concat(`${category.id},`);
        });
        if (str.endsWith(',')) {
            str = str.slice(0, -1);
        }

        this.recipientAttributes = str;
    }

    private defaultNameSort(): void {
        this.columnSelectedForSorting = "name";
        this.direction = 1;
    }

    /**
     * Function used to sort recipients response either by name or email.
     * @param sortBy 
     */
    public sortRecipients(property): void {
        this.isDesc = !this.isDesc;
        this.columnSelectedForSorting = property;
        this.direction = this.isDesc ? 1 : -1;
        switch (property) {
            case 'name':
                this.isNameAsc = !this.isNameAsc;
                break;
            case 'email':
                this.isEmailAsc = !this.isEmailAsc;
                break;
            case 'participantType':
                this.isTypeAsc = !this.isTypeAsc;
                break;
        }
    }

    /**
     * Function used to process when the user clicks on select all  option under RecipientAttributes in right panel
     * @param $event 
     */
    public selectAllRecipientAttributes(isChecked: boolean): void {
        this.categories.forEach((category: ICategoryData, index: number) => {
            category.checked = isChecked;
            if (index > 0) {
                this.selectSelectedRecipientAttributes(category, !isChecked, category);
            }
        });
    }

    /**
     * Function used to process selected Receipient in right panel
     * @param recipient
     */
    public selectSelectedRecipientAttributes(recipient: ICategoryData, isCategorySelected: boolean, selectedCategory: ICategoryData): void {
        const selectedRecipientNameId = `${recipient?.name?.toLocaleLowerCase()}-${recipient?._id}`;
        if (isCategorySelected) {
            if (this.selectedReceipent.has(selectedRecipientNameId) && selectedCategory) {
                --this.selectedCategoriesCount;
            }
            this.recipientAttributes.replace(`,${recipient.id},`, ',');
            this.selectedReceipent.delete(selectedRecipientNameId);
        } else {
            if (!this.selectedReceipent.has(selectedRecipientNameId) && selectedCategory) {
                ++this.selectedCategoriesCount;
            }
            this.selectedReceipent = new Map([...this.selectedReceipent.set(selectedRecipientNameId, recipient).entries()].sort());
            let attributes = '';
            this.selectedReceipent.forEach((val: ICategoryData) => {
                attributes = attributes.concat('', `,${val.id}`);
            });
            this.recipientAttributes = attributes.substring(2);
        }
    }
    /**
     * Function used to update list in left panel and make API call to get the count
     * @param recipient
     */
    public recipientsSelected(recipient: ICategoryData, isChecked: boolean, categoryIndex?: number): void {
        categoryIndex = categoryIndex ? categoryIndex : this.categories.findIndex((category: ICategoryData) => category._id === recipient._id);
        if (recipient && recipient.name) {
            this.recipients = [];

            if (recipient.id === 'selectAll') {
                this.selectAllRecipientAttributes(isChecked);
            } else {
                const selectedRecipientNameId = `${recipient?.name?.toLocaleLowerCase()}-${recipient?._id}`;
                const isCategoryAlreadySelected = this.selectedReceipent.has(selectedRecipientNameId);
                this.selectSelectedRecipientAttributes(recipient, isCategoryAlreadySelected, this.categories[categoryIndex]);
                if (this.categories[categoryIndex]) {
                    this.categories[categoryIndex].checked = !isCategoryAlreadySelected;
                }
            }
            this.categories[0].checked = this.selectedCategoriesCount === this.categories?.length - 1;
            this.getRecipientsList();
            if (!this.selectedReceipent.size) {
                this.closeRecipients();
            }

        } else {
            // @TODO what if name is not coming from API response
        }
    }

    /**
     * Function used to clear all the selected recipients
     */
    public clearrecipientsSelected(): void {
        const attribute: any = document.getElementsByName('attribute');
        for (let i = 0; i < attribute.length; i++) {
            if (attribute[i].checked) {
                attribute[i].checked = false;
            }
        }
        const selectAllObj: ICategoryData = <ICategoryData>{
            name: 'Select All',
            id: 'selectAll'
        };
        this.recipientsSelected(selectAllObj, false);
        this.clearRecipientCount();
    }

    /**
     * Function used to close Recipients list
     */
    public closeRecipients(): void {
        this.hideRecipientSection = true;
    }

    getRecipientsList() {
        this.recipientErrorMessage = {};
        this.isRecipientCount = true;
        this.getRecipientAttributes();
        if (!this.recipientAttributes) {
            this.recipientsCount = null;
            return;
        }
        this.getRecipientAttributeList();
        this.notificationService.getRecipients(this.category, this.recipientAttributes, this.dtStart, this.dtEnd)
            .pipe(take(1))
            .subscribe((response: IRecipient[]) => {
                const tempRecipient: IRecipient[] = [];
                response.map((receipt: IRecipient) => {
                    if (receipt) {
                        receipt.name = `${receipt.lastName}, ${receipt.firstName}`;
                        tempRecipient.push(receipt);
                    }
                });
                this.defaultNameSort();
                this.recipients = tempRecipient;
                this.recipientsCount = tempRecipient?.length;
                this.isRecipient = this.recipientsCount > 0;
            },
                () => {
                    this.recipientErrorMessage = {
                        text: API_USER_DEFINED_MESSAGE.FAILED
                    };
                });
    }

    public showRecipientsList(): void {
        this.isRecipient = true;
        this.hideRecipientSection = false;
        if (this.recipients.length) {
            return;
        }

    }

    clearError() {
        this.isError = false;
    }

    openConfirmDialog(body: string): Observable<undefined> {
        let yesBtn = this.sendCategory === "schedule" ? "Schedule" : "Send Now";
        return this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: "",
                body: body,
                buttonTextNo: "Keep Editing",
                buttonTextYes: yesBtn
            },
            panelClass: 'notification-submit-dialog'
        }).afterClosed();
    }

    openAlertDialog(body: string): Observable<undefined> {
        return this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: "",
                body: body,
                buttonTextNo: null,
                buttonTextYes: "Ok"
            },
            panelClass: 'notification-submit-dialog'
        }).afterClosed();
    }
    confirmCancel(nextUrl: string = '') {
        this.dialog.open(ConfirmDialogComponent, {
            data: {
                title: "",
                body: "Are you sure you want to exit and lose changes?",
                buttonTextNo: "Keep Editing",
                buttonTextYes: "Exit"
            },
            panelClass: 'notification-cancel-dialog'
        }).afterClosed()
            .pipe(take(1))
            .subscribe(result => {
                if (result) {
                    if (nextUrl) {
                        this.isSelectCategory = false;
                        this.isCategorySelected = false;
                        this.notificationHistory.filteredNotificationsResponse = [];
                        this.router.navigate([nextUrl]);
                    }
                    this.cancel();
                    this.isSuccess = this.isSelectCategory = false;
                    this.isError = false;
                    this.clearAllFilters();
                }
                else if (!result && nextUrl === '/admin/programs-external') {
                    this.navMenuService.isFacilitatorView();
                    this.navMenuService.disableDropdownMenu$.next('admin');
                }
            });
    }

    /**
     * Function used to take all selected category names and put into comma delimited list
     */
    private processCategoryNames(): void {
        let selectedCategoryItemNamesArray: Array<Object> = [];
        this.selectedReceipent.forEach((selectedObject: ICategoryData) => {
            const payload: any = {
                name: selectedObject?.name,
                id: selectedObject?.id
            };
            if (selectedObject.dtStartTime) {
                const dtStartTime24Hours = moment(selectedObject.dtStartTime, ["h:mm A"]).format("HH:mm:ss");
                payload.dtStart = `${moment(selectedObject.dtStart).format('YYYY-MM-DD')} ${dtStartTime24Hours}`;
            }
            if (selectedObject.dtEndTime) {
                const dtEndTime24Hours = moment(selectedObject.dtEndTime, ["h:mm A"]).format("HH:mm:ss");
                payload.dtEnd = `${moment(selectedObject.dtEnd).format('YYYY-MM-DD')} ${dtEndTime24Hours}`;
            }
            if (this.category === 'meeting') {
                payload.dtStart = `${this.dtStart} 00:00:00`;
                payload.dtEnd = `${this.dtEnd} 23:59:00`;
            }
            selectedCategoryItemNamesArray.push(payload);
        });
        // Convert array into a comma delimited list
        this.selectedCategoryItemNames = selectedCategoryItemNamesArray;
    }

    save() {
        if (!this.validateRecipients()) {
            this.openAlertDialog('You must select at least one recipient.');
            return;
        }

        this.processRecipients();
        this.processCategoryNames();

        if (this.messageType == '1') {
            this.saveAlert();
        } else if (this.messageType == '2') {
            this.saveAnnouncement();
        } else if (this.messageType == '3') {
            this.saveBoth();
        }
    }

    onSaveSuccess() {
        this.isSuccess = true;
        this.isError = false;
        this.refreshScreen();
    }

    onSaveError() {
        this.isSuccess = false;
        this.isError = true;
    }

    refreshScreen() {
        this.notificationHistory.initializeValues();
        setTimeout(() => {
            this.initializeValues();
            const cattype: any = document.getElementsByName('cattype');
            const sendcattype: any = document.getElementsByName('sendcattype');
            for (let i = 0; i < sendcattype.length; i++) {
                if (sendcattype[i]?.type == "radio") {
                    sendcattype[i].checked = false;
                }
            }
            for (let x = 0; x < cattype.length; x++) {
                if (cattype[x]?.type == "radio") {
                    cattype[x].checked = false;
                }
            }
        }, 4000);
    }

    saveAnnouncement() {
        this.showGlobalSpinner = true;
        let date = moment(this.sendDate).format('YYYY-MM-DD');
        let dateTime = date + ' ' + this.sendTime + ":00";
        this.notificationService.sendNotification(this.category, this.messageType, this.announcementTitle, this.announcementMessage, this.participants, this.email, this.selectedCategoryItemNames, '', this.sendCategory, dateTime)
            .pipe(take(1))
            .subscribe(
                () => {
                    this.showGlobalSpinner = false;
                    this.hideRecipientSection = true;
                    this.onSaveSuccess();
                    this.isRecipient = false;
                },
                () => {
                    this.showGlobalSpinner = false;
                    this.onSaveError();
                }
            );
    }

    saveAlert() {
        this.showGlobalSpinner = true;
        let date = moment(this.sendDate).format('YYYY-MM-DD');
        let dateTime = date + ' ' + this.sendTime + ":00";
        this.notificationService.sendNotification(this.category, this.messageType, '', '', this.participants, this.email, this.selectedCategoryItemNames, this.alertBody, this.sendCategory, dateTime)
            .pipe(take(1))
            .subscribe(
                () => {
                    this.showGlobalSpinner = false;
                    this.hideRecipientSection = true;
                    this.onSaveSuccess();
                    this.isRecipient = false;
                },
                () => {
                    this.showGlobalSpinner = false;
                    this.onSaveError();
                }
            );
    }

    saveBoth() {
        this.showGlobalSpinner = true;
        let date = moment(this.sendDate).format('YYYY-MM-DD');
        let dateTime = date + ' ' + this.sendTime + ":00";
        this.notificationService.sendNotification(this.category, this.messageType, this.announcementTitle, this.announcementMessage, this.participants, this.email, this.selectedCategoryItemNames, this.alertBody, this.sendCategory, dateTime)
            .pipe(take(1))
            .subscribe(
                () => {
                    this.showGlobalSpinner = false;
                    this.hideRecipientSection = true;
                    this.onSaveSuccess();
                    this.isRecipient = false;
                },
                () => {
                    this.showGlobalSpinner = false;
                    this.onSaveError();
                }
            );
    }

    resetScreen() {
        this.titleWordCount = '0';
        this.bodyWordCount = '0';
        this.recipientCount = '';
        this.recipients$ = null;
        this.recipients = [];
        this.isRecipientCount = false;
        this.recipientsCount = null;
        this.recipientErrorMessage = {};
        this.selectedAttributes$ = null;
    }

    cancel() {
        this.isAlert = false;
        this.isAnnouncement = false;
        this.messageType = null;
        this.isCategorySelected = false;
        this.isRecipient = false;
        this.isSuccess = false;
        this.alertBody = '';
        this.announcementTitle = '';
        this.announcementMessage = '';
        this.resetScreen();
    }

    /**
     * Function used to check and uncheck the participents
     */
    public checkUnCheckParticipants($event): void {
        const participants: any = document.getElementsByName('participant');
        const isChecked = $event.target.checked;
        for (let i = 0; i < participants.length; i++) {
            participants[i].checked = isChecked;
        }
    }

    clearAlert() {
        if (this.messageType == '3') {
            this.messageType = '2';
        } else {
            this.messageType = null;
            this.isSelectCategory = false;
        }
        this.isAlert = false;
        this.alertBody = '';
        this.clearAllFilters();
    }

    clearAnnouncement() {
        if (this.messageType == '3') {
            this.messageType = '1';
        } else {
            this.messageType = null;
            this.isSelectCategory = false;
        }
        this.isAnnouncement = false;
        this.announcementTitle = '';
        this.announcementMessage = '';
        this.clearAllFilters();
    }

    clearBoth() {
        this.messageType = null;
        this.isAlert = false;
        this.isAnnouncement = false;
    }

    clearMessageType() {
        this.isAlert = false;
        this.isAnnouncement = false;
    }

    /**
     * Function used to clear all the filters
     */
    public clearAllFilters(): void {
        this.clearMessageCategory();
        this.clearFilter();
        this.clearRecipientCount();
        this.clearCategoriesSection();
        this.isCategorySelected = false; // Recipient Attributes section
        this.showCategoriesSection = false;
        this.hideRecipientSection = true;
        this.displayFilter = false;
        this.isFilter = false;
        this.category = null;
        this.categoryDescription = "";
        this.clearSelectedReceipent();
    }

    public clearSelectedReceipent(): void {
        this.selectedReceipent.clear();
    }



    /**
     * Function used to clear selected message category
     */
    private clearMessageCategory(): void {
        const attributes: any = document.getElementsByName('cattype');
        for (let i = 0; i < attributes.length; i++) {
            if (attributes[i].checked) {
                attributes[i].checked = false;
                break;
            }
        }
    }

    /**
     * Function used to clear categories section in right panel
     */
    private clearCategoriesSection(): void {
        this.categories = [];
        this.categoriesErrorMessage = '';
    }
    /**
     * Function used to clear Recipient Attributes in left panel
     */
    public clearRecipientCount(): void {
        this.recipientsCount = null;
        this.recipientErrorMessage = {};
        this.isRecipientCount = null;
        this.isRecipient = false;
        this.isNameAsc = true;
        this.isEmailAsc = false;
        this.isTypeAsc = false;
        this.selectedCategoriesCount = 0;
        this.recipients = [];
    }

    selectSendCategory($event): void {
        $event.target.checked = true;
        this.sendCategory = $event.target.value;
        this.isFutureDated = $event.target.value == "now" ? true : false;

        if (this.sendCategory === "schedule") {
            this.showSpecificDateControls = true;
        }
        else {
            this.showSpecificDateControls = false;
        }
    }

    sendDateChange() {
        this.validateIfFutureDated();
    }

    sendTimeChange(event): void {
        this.sendTime = event?.value;
        this.displaySendTimePicker = false;
        this.validateIfFutureDated();
    }

    private validateIfFutureDated(): void {
        if (this.sendTime && this.sendDate) {
            const hoursAndMinutes = this.sendTime.split(':');
            this.sendDate.setHours(hoursAndMinutes[0], hoursAndMinutes[1]);
            const timeInEST = this.utilsService.changeTimeZone(new Date(), 'America/New_York');
            this.isFutureDated = this.sendDate > timeInEST;
        }
    }

    toggleRecipents() {
        this.hideHistoryRecipientSection = !this.hideHistoryRecipientSection;
    }
}