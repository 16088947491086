<div *ngIf="showGlobalSpinner">
  <div class="loading">
    <mat-spinner></mat-spinner>
  </div>
</div>

<div setting-container class="all" id="notifications">
  <!-- <div><span><img src="assets/icons/search-nav.svg"><img src="assets/icons/ad-hoc-announcement.svg"></span></div>-->
  <!-- <div class="row">-->
  <div breadcrumbs [option]='breadcrumbOptions'></div>
  <!-- </div>-->
  <div left class="left m-l-md  m-t-xxl">
    <div class="header4 jp-main-title">Ad-Hoc Notifications</div>
    <div class="row">
      <hr class="w-100">
    </div>
    <div class="jp-error" *ngIf="isError"><img class="jp-error-icon" src="assets/icons/warning-overdue.svg"> Error occurred. Please try again. <img class="jp-error-icon jp-cursor-link"
        src="assets/icons/close-drawer.svg" (click)="clearError()"></div>
    <div class="success jp-success"  *ngIf="isSuccess">Sent Successfully <img src="assets/icons/success.svg"> </div>
    <div class="row m-t-md">
      <div><span class="header3">New Message </span> </div>
      <div><span class="p-sm jp-cursor-link font-size-14 font-weight-400" (click)="confirmCancel()"> Cancel </span>
        <span class="jp-cursor-link btn font-size-14" (click)="confirmsave()">Submit</span>
      </div>
    </div>
    <div class="notification-panel">

      <div class="panel-header border-bottom-grey">
        <div class="flex-center-align header1 width-50 p-l-xs m-t-xs m-b-xs">
          <img src="assets/icons/ad-hoc-message-type.svg">
          <span class="p-l-9px p-r-xs bottom-0">Message Type</span>
          <span class="required">*</span>
        </div>
        <!--<div>
          <span *ngIf="isAnnouncement && isAlert" (click)="clearBoth()">
            <img src="assets/icons/close-up.svg" class="jp-cursor-link">
          </span>
        </div>-->
      </div>

      <div *ngIf="!isAnnouncement && !isAlert" class="panel-row">
        <div class="textgreysmall jp-txt-center p-t-b-sm p-l-r-xs" >Select Message Type from Message Attributes in right panel to begin
        </div>
      </div>

      <div class="jp-message-type-subitem-box m-b-sm">
        <div *ngIf="isAlert" [ngClass]="{'p-b-0': isAnnouncement && isAlert }" >

          <div class="panel-header border-bottom-grey m-l-md p-l-0">
            <div class="flex-center-align header1 width-50 m-t-xs m-b-xs">
              <img class="jp-message-type-img" src="assets/icons/ad-hoc-alert.svg">
              <span class="p-l-xs p-r-xs bottom-0">Alert</span>
            </div>
            <div>
              <span (click)="clearAlert()">
              <img width="25" height="25" src="assets/icons/close-drawer.svg" class="jp-cursor-link p-t-sm">
            </span>
            </div>
          </div>

          <div class="m-l-xs">
            <div class="panel-row">
              <div class="textgreysmall p-l-8">Alert Body <span class="required"> *</span></div>
              <div class="textgreysmall">{{alertBody?.length}} / 150</div>
            </div>
            <div class="panel-row">
              <textarea class="textarea jp-textarea-body panel-row-textgreysmall" rows="1" name="alert" id='alertBody' maxlength="150" value="" cols="50" (keydown)="wordCounter()"
                [(ngModel)]="alertBody" placeholder="Add message" onfocus="this.placeholder=''" onblur="this.placeholder='Add message'"
                maxlength="150"></textarea>
            </div>
          </div>

        </div>

        <div [ngClass]="{'border-top-grey': isAnnouncement && isAlert }"></div>

        <div *ngIf="isAnnouncement" class="announce-comp">
          <div class="panel-header border-bottom-grey m-l-md p-l-0">
            <div class="flex-center-align header1 width-50 m-t-xs m-b-xs">
              <img class="jp-message-type-img" src="assets/icons/ad-hoc-announcement.svg">
              <span class="p-l-xs p-r-xs bottom-0">Announcement</span>
            </div>
            <div>
              <span (click)="clearAnnouncement()">
              <img width="25" height="25" src="assets/icons/close-drawer.svg" class="jp-cursor-link p-t-sm">
            </span>
            </div>
          </div>

          <div  class="m-l-xs">
            <div class="panel-row">
              <div class="textgreysmall p-l-8">Announcement Title <span class="required"> *</span></div>
              <div class="textgreysmall">{{announcementTitle?.length}} / 50</div>
            </div>
            <div class="panel-row">
              <textarea class="textarea m-b-sm panel-row-textgreysmall" rows="1" cols="50" name="announceTitle" id='announceTitle' value="" (keydown)="wordCounter()"
                [(ngModel)]="announcementTitle" placeholder="Add title" onfocus="this.placeholder=''" onblur="this.placeholder='Add title'"
                maxlength="50"></textarea>
            </div>
            <div class="panel-row">
              <div class="textgreysmall p-l-8">Announcement Body <span class="required"> *</span></div>
              <div class="textgreysmall">{{characterCount}} / {{maxCharacters}}</div>
            </div>
            <div class="panel-row flex-direction-announce">
                  <ngx-editor-menu class="rich-text-tool" [editor]="editor"
                  [toolbar]="toolbar"> </ngx-editor-menu>
                  <form class="panel-row-textgreysmall" [formGroup]="form">
                  <ngx-editor formControlName="content" 
                  (ngModelChange)="onContentChange($event)" [editor]="editor" (keydown)="wordCounter($event)" [(ngModel)]="announcementMessage" [disabled]="false" [placeholder]="'Add message'">
                  </ngx-editor>
                </form>
            </div>
          </div>
        </div>
      </div>

      <div class="panel-header margin-top-10 border-bottom-grey m-b-sm">
        <div class="flex-center-align header1 width-50 p-l-xs">
          <img *ngIf="!isCategorySelected" src="assets/icons/category.svg">
          <img class="img-size" *ngIf="category === 'program'" src="assets/icons/programs-title.svg">
          <img class="img-size" *ngIf="category === 'course'" src="assets/icons/courses-title.svg">
          <img *ngIf="category === 'meeting'" src="assets/icons/meeting-room-title.svg">
          <img *ngIf="category === 'session'" src="assets/icons/events-title.svg">
          <img *ngIf="category === 'house'" src="assets/icons/house-title.svg">
          <img *ngIf="category === 'guest'" src="assets/icons/participants-title.svg">
          <img *ngIf="category === 'facilitator'" src="assets/icons/facilitator-icon.svg">
          <span class="p-l-9px p-r-xs bottom-0">Category <span
              class="required">*</span>
          </span>
        </div>
        <div class="m-t-sm m-b-sm">
          <span *ngIf="!categoryDescription" class="textgreysmall m-t-md m-b-sm">Pending</span>

          <span *ngIf="categoryDescription" class="textbluesmall background-color-solitude border-radius-16 position-relative p-r-xxl p-l-sm p-t-sm p-b-sm m-r-xs top-2">{{categoryDescription}}
            <img (click)="clearAllFilters()" src="assets/icons/clear-chip.svg"
              class="jp-cursor-link position-absolute category__remove">
          </span>
        </div>
      </div>
      <div class="panel-header margin-top-10 border-bottom-grey">
        <div class="flex-center-align header1 width-50 p-l-xs">
          <img src="assets/icons/ad-hoc-recipient-attributes.svg">
          <span class="p-l-9px p-r-xs bottom-0">Recipient Attributes <span class="required">*</span></span>

        </div>
        <div  *ngIf="!isRecipientCount && !selectedReceipent?.size; else displayRecipientCount" class=" m-t-sm m-b-sm"><span class="textgreysmall m-t-md m-b-sm">Pending</span></div>
        
            <ng-template #displayRecipientCount>
              <div  *ngIf="recipientsCount  && selectedReceipent?.size; else displayCountErrorMessage" class="m-t-sm m-b-sm m-r-xs"><span class="textbluesmall jp-recp-numb jp-cursor-link font-weight-bold" 
                (click)="showRecipientsList()">See recipients ({{recipientsCount}})</span>
                <span  class="textbluesmall jp-recp-numb jp-cursor-link font-weight-bold m-l-sm m-r-xs" (click)="clearrecipientsSelected(); clearSelectedReceipent()">Clear </span>
              </div>
            </ng-template>
            <ng-template #displayCountErrorMessage>
              <div class="m-t-0 m-b-0 m-r-xs width-60" *ngIf="recipientErrorMessage?.text"><span class="jp-recp-numb font-weight-bold"> <error-message></error-message></span>
              </div>
            </ng-template>
            
      </div>

      <div catvaluesselected *ngIf="selectedReceipent.size" class="border">
        <div class="category-container">
          <div *ngFor="let attr of selectedReceipent | keyvalue:indexOrderAsc" class="m-sm">
            <div class="background-color-solitude border-radius-16 category-item">
              <div>
                <div class="category-item-overflow textbluesmall" title="{{attr?.value?.name }}">
                  {{attr?.value?.name }}
                </div>
                <div *ngIf="attr?.value?.dtStart" class="category-item-overflow textbluesmall">
                  <span class="text-gray-light" title="{{ attr?.value?.display }}">{{ attr?.value?.display }}</span>
                </div>
              </div>
              <div class="m-l-xs">
                <img (click)="recipientsSelected(attr.value, $event)" src="assets/icons/clear-chip.svg" class="jp-cursor-link">
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="panel-header margin-top-10 border-bottom-grey">
        <div class="flex-center-align header1 width-50 p-l-xs m-t-xs m-b-xs">
          <img src="assets/icons/ad-hoc-schedule.svg">
          <span class="p-l-9px p-r-xs bottom-0">Message Schedule</span>
          <span class="required">*</span>
        </div>
      </div>

      <div class="schedule-box p-t-md p-r-md p-l-md" [ngClass]="{'p-b-md': !showSpecificDateControls}">

        <div>
          <div class="schedule-radio-box">
            <div>
              <div class="jp-cat-opt font-size-16">
                <div><input type="radio" name="sendcattype" value="now" (click)="selectSendCategory($event)"></div>

                <div class="m-l-sm">
                  Send now
                </div>
              </div>
            </div>
            <div>
              <div class="jp-cat-opt font-size-16 m-l-md">
                <div><input type="radio" name="sendcattype" value="schedule" (click)="selectSendCategory($event)"></div>

                <div class="m-l-sm">
                  Schedule
                </div>
              </div>
            </div>
          </div>

          <div class="schedule-input-box border-top-grey m-t-md" *ngIf="showSpecificDateControls">
            <div>
              <mat-form-field floatLabel="always">
                <mat-label for="one-time-date">Date</mat-label>
                <input matInput name="one-time-date" [matDatepicker]="picker" [(ngModel)]="sendDate" (ngModelChange)="sendDateChange()" 
                [min]="minDate" [max]="maxDate" readonly (click)="picker.open()"
                placeholder="mm/dd/yyyy" autocomplete="off">
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="position-relative">
              <mat-form-field appearance="legacy" class="time_range position-relative" (click)="displaySendTimePicker = !displaySendTimePicker"
                [floatLabel]="'never'">
                <input matInput name="one-time-time" value="{{sendTime | dateFormat :'missedAddedTime'}}" placeholder="00:00" autocomplete="off" readonly>
                <mat-icon class="position-absolute right-0 top-0 sendTimeIcon">access_time</mat-icon>
              </mat-form-field>
              <time-picker [dateTime]="sendTime" (cancel)="displaySendTimePicker=false" (update)="sendTimeChange($event)" *ngIf="displaySendTimePicker"
              [minutesArr] = "minuteIntervalArr" class="sendTimePicker"></time-picker>
            </div>
          </div>
        </div>

      </div>

    </div>

  </div>

  <div right class="right">
    <div [hidden]="!hideRecipientSection" setting-panel class="toppanel">
      <mat-tab-group  class="mat-tab-fill-height height-100" >
        <mat-tab>
          <ng-template mat-tab-label>
            <div class="right__message-attribute">
              <p class="pull-left m-r-sm m-t-sm"><img src="assets/icons/ad-hoc-message-attributes-selected.svg"></p>
              <p class="right__message-title font-weight-700 font-size-17 font-weight-bold text-cyan-blue  m-t-xs">Message Attributes
              </p>
            </div>
          </ng-template>

      <div class="w-100" class="border-top-grey"></div>
      <div class="header1 m-l-xs">
        <div class="p-r-sm font-arial font-size-18 font-weight-700 m-l-md m-t-md position-relative">Message Type
          <div class="message_type__tooltip tooltip m-t-0 m-l-sm position-absolute"><img src="assets/icons/help-inactive.svg">
            <div class="tooltip-text">
              <div class="p-sm text-align-left font-size-16 line-hight-22">
                <p class="font-arialmt font-weight-400 m-0">Select type(s) of message you wish to send to recipents.</p>
                <p class="font-arial-boldmt  font-weight-700">Message Types include:</p>
                <p class="font-arialmt font-weight-400 m-0"> <span class="arial-boldmt  font-weight-700">Alert:</span> Messaging presented to guests on the locked screen
                  and Notification Center of their phone that is meant to drive immediate action.</p>
                <p class="font-arialmt font-weight-400 m- m-t-sm"> <span class="arial-boldmt  font-weight-700">Announcement:</span> Persistent messaging displayed in the app
                  that guests can refer to repeatedly.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="jp-message-type-box m-l-md font-size-16 font-arial font-weight-400 m-t-sm p-l-0 m-b-md">
        <div><input type="radio" class="m-r-sm" [(ngModel)]="messageType" value="1" name="alert" (click)="addMessageType($event)">          Alert Only </div>
        <div class="m-l-md"><input type="radio" class="m-r-sm" [(ngModel)]="messageType" value="2" name="alert" (click)="addMessageType($event)">          Announcement Only </div>
        <div class="m-l-md"><input type="radio" class="m-r-sm" [(ngModel)]="messageType" value="3" name="alert" (click)="addMessageType($event)">          Alert and Announcement </div>
      </div>

      <div class="panel-header border-top-grey m-t-sm m-l-xs m-r-md">
        <div class="flex-center-align header1">
          <div class="p-r-sm font-arial font-size-18 font-weight-700 m-l-sm m-t-md position-relative">Message Category
            <div class="tooltip m-t-0 position-absolute m-l-sm"><img src="assets/icons/help-inactive.svg">
              <div class="tooltip-text">
                <div class="p-sm text-align-left font-size-16 line-hight-22">
                  <p class="font-arialmt font-weight-400 m-0">Select the category that best identifies the audience type for your message. For example, select "Course"
                    to identify recipients by their course registration.
                  </p>
                </div>
              </div>
            </div>
          </div>

          <!-- <img src="assets/icons/help-inactive.svg" class="m-t-md"> -->
        </div>
        <div class="m-t-md">
          <!-- <span class="textblue jp-cursor-link" (click)="getRecipientCount($event)">Apply</span> -->
          <!-- <span class="textbluepad jp-cursor-link"   (click)="clearMessageCategory()">Clear All</span > -->
          <a class="p-l-xxl font-size-17" [ngClass]="isCategorySelected ? 'text-cyan-blue  jp-cursor-link' : 'disabled' " (click)="clearAllFilters()">Clear All</a>
        </div>
      </div>

      <div class="jp-message-box">
        <table>
          <tr>
            <td>
              <div class="jp-cat-opt font-size-16">
                <div><input type="radio" name="cattype" value="course" (click)="selectCategory($event)"></div>
                <div class="icon-container"><img src="assets/icons/courses-title.svg" class="jp-cat-img"></div> Course
              </div>
            </td>
            <td>
              <div class="jp-cat-opt font-size-16">
                <div><input type="radio" name="cattype" value="meeting" (click)="selectCategory($event)"></div>
                <div class="icon-container"><img src="assets/icons/meeting-room-title.svg" class="jp-cat-img"></div> Meeting Room
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="jp-cat-opt font-size-16">
                <div><input type="radio" name="cattype" value="guest" (click)="selectCategory($event)"></div>
                <div class="icon-container"><img src="assets/icons/participants-title.svg" class="jp-cat-img"></div> Guest
              </div>
            </td>
            <td>
              <div class="jp-cat-opt font-size-16">
                <div><input type="radio" name="cattype" value="program" (click)="selectCategory($event)"></div>
                <div class="icon-container"><img src="assets/icons/programs-title.svg" class="jp-cat-img"></div> Program
              </div>
          </tr>
          <tr>
            <td>
              <div class="jp-cat-opt font-size-16">
                <div><input type="radio" name="cattype" value="house" (click)="selectCategory($event)"></div>
                <div class="icon-container"><img src="assets/icons/house-title.svg" class="jp-cat-img"></div>
                <div class="message-title">House and Well-Being Event</div>
              </div>
            </td>
            <td>
              <div class="jp-cat-opt font-size-16">
                <div><input type="radio" name="cattype" value="session" (click)="selectCategory($event)"></div>
                <div class="icon-container"><img src="assets/icons/events-title.svg" class="jp-cat-img"></div> Session
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <div class="jp-cat-opt font-size-16">
                <div><input type="radio" name="cattype" value="facilitator" (click)="selectCategory($event)"></div>
                <div class="icon-container"><img src="assets/icons/facilitator-icon.svg" class="jp-cat-img"></div> Facilitator
              </div>
            </td>
          </tr>
        </table>
      </div>

      <div *ngIf="isCategorySelected" class="panel-category-select">
        <div class="jp-category-title m-b-md text-shade-gray font-arial font-size-12">Recipient Attributes</div>
        <div>
          <div class="panelgrey p-sm">
            <div class="jp-filter-box" [ngClass]="categoriesErrorMessage ? 'align-flex-end' : ''">
              <div class="jp-filter-name width-100 m-t-xs divider bottom m-r-md font-arial">
                <span *ngIf="selectedCategoriesCount; else selectCourses">
                  {{ selectedCategoriesCount }} selected
                </span>
                <ng-template #selectCourses>
                  <span class="text-gray-light font-weight-500">
                    Select {{ categorySelectName }}(s)
                  </span>
                </ng-template>
                <a class="pull-right font-size-16 font-arial font-weight-700 font-arial" [ngClass]="selectedReceipent?.size ? 'text-cyan-blue jp-cursor-link' : 'disabled' "
                  (click)="clearrecipientsSelected()">
                  Clear
                </a>
              </div>
              <div class="right__message-attribute jp-filter-switch jp-cursor-link" [ngClass]="isFilterActive ? 'jp-filter-active' : 'jp-filter-inactive'"
                (click)="showFilter()">
                <p class="pull-left m-l-sm right__message-filter">
                  <img [src]="'assets/icons/filter-table-button' + (isFilterActive ? '-active' : '') + '.svg'">
                  <span class="font-arial font-size-14  font-weight-400 m-l-xs">Filters</span>
                </p>
              </div>


              <!-- <div class="jp-filter-switch" (click)="showFilter()">
                    <span class="font-arial font-weight-400 coloe">
                    Filters
                  </span>
                </div> -->
            </div>
            <!-- <div class="jp-select-notice"></div><div></div> -->
          </div>
        </div>
        <div *ngIf="displayFilter">

          <div class="jp-filter-row jp-filter-row-reset">
            <div class="jp-filter-param-box">
              <div class="jp-filter-actions">
                <div class="filter-label font-size-14 font-weight-400 text-shade-gray">Filters</div>
                <div class="filter-actions-holder">
                  <span class="filter-action-link  m-r-sm font-size-14" (click)="runFilter()"  [ngClass]="(isFilterApplyEnabled) ? 'jp-cursor-link  blue-color' : 'disabled cursor-not-allowed text-gray-light'">Apply</span>

                  <span class="filter-action-link jp-cursor-link font-size-14" [ngClass]="isFilter ? 'blue-color':'disabled cursor-not-allowed'" (click)="clearFilter()">Clear</span>
                </div>
              </div>

              <div class="jp-filter-today">
                <input type="radio" name="filter" [checked]="filterTodayChecked" value="today" id="today" (click)="processFilter($event)">
                <span class="m-l-sm font-size-16">Today</span>
              </div>

              <div class="jp-filter-on">
                <input type="radio" name="filter" [checked]="filterOnChecked" value="date" id="date" (click)="processFilter($event)">
                <span class="m-l-sm font-size-16">On</span>
                <div [ngClass]="filterOnChecked ? '' : 'disabled cursor-not-allowed'">
                  <input type="date" id="dThisdate" name="date" class="jp-date-input font-size-16 text-gray-light" placeholder="Date" [(ngModel)]="filterName1"
                  name="filterName1" (ngModelChange)="onFilterDateChanged($event, 'on')" [ngClass]="!isFilterOnValid ? 'red-bottom-border' : '' ">
                </div>

              </div>

              <div class="jp-filter-to">
                <input type="radio" name="filter" [checked]="filterFromChecked" value="from" id="from" (click)="processFilter($event)">
                <span class="m-l-sm font-size-16">From</span>
                <div [ngClass]="filterFromChecked ? '' : 'disabled cursor-not-allowed'">
                <input type="date" id="dtStart" name="from" class="jp-date-input font-size-16 text-gray-light" placeholder="Start Date" [(ngModel)]="filterName2"
                  name="filterName2" (ngModelChange)="onFilterDateChanged($event, 'from-start')" [ngClass]="!isFilterFromStartValid ? 'red-bottom-border' : '' ">
                  </div>
                <div class="filter-to-label m-t-sm"><span class="m-l-lg">to</span></div>
                <div [ngClass]="filterFromChecked ? '' : 'disabled cursor-not-allowed'">
                <input type="date" id="dtEnd" name="dateto" class="jp-date-input font-size-16 text-gray-light" placeholder="End Date" [(ngModel)]="filterName3"
                  name="filterName3" (ngModelChange)="onFilterDateChanged($event, 'from-end')" [ngClass]="!isFilterFromEndValid ? 'red-bottom-border' : '' ">
                  </div>
              </div>

              <div class="m-t-xs m-b-xs time_filter" [hidden]="(category !== 'course') || (filterType === 'from')">
                <div>
                  <input type="checkbox" name="dateRange" (click)="enableOrDisableTimeSelection($event)" [disable]="disableTimeSelectionCheckBox"
                  [checked]="!disableTimeSelection" class="font-size-16">
                    <dropdown [labelCssClass]="'font-size-15 m-b-xs'" [lablel]="'Start and/or End Time'" [value]="startOrEndTime" [disable]="disableTimeSelection || disableTimeSelectionCheckBox"
                    [dropdownValues]="startAndEndTimeDropdownValues"
                    (valueChage)="startOrEndTimeSelected($event)" class="inline-block width-170" ></dropdown>
                </div>
                <div class="m-t-xs m-b-xs m-l-xl position-relative" *ngIf="startOrEndTime">
                  <div class="jp-filter-time__type inline-block">
                    <div class="width-40 inline-block">
                      <dropdown [lablel]="'When'" [value]="selectedTimeType" [disable]="!startOrEndTime || disableTimeSelection || disableTimeSelectionCheckBox"
                    [dropdownValues]="startDateRange"
                    (valueChage)="timeTypeSelected($event)" class="inline-block width-100" ></dropdown>
                    </div>

                    <!-- <div class="time-row inline-block m-l-xl"> -->
                    <time-picker [dateTime]="selectedStartTime" (cancel)="displayFromTimePicker=false" (update)="updateFromTime($event)" *ngIf="displayFromTimePicker"
                      class="position-relative top-25 time_range-panel"></time-picker>


                    <!-- </div> -->

                    <div class="width-45 inline-block m-l-sm cursor-pointer" [ngClass]="(!startOrEndTime || disableTimeSelection || disableTimeSelectionCheckBox) ? 'cursor-not-allowed' : '' ">
                      <div class="time-row inline-block m-l-xs" [ngClass]="(!startOrEndTime || disableTimeSelection || disableTimeSelectionCheckBox) ? 'disabled' : '' ">
                        <mat-form-field appearance="legacy" class="time_range position-relative" (click)="displayFromTimePicker = !displayFromTimePicker"
                          [floatLabel]="'never'">
                          <input matInput [value]="selectedStartTime  | dateFormat :'missedAddedTime'" class="font-size-16">
                          <mat-icon svgIcon="timeTable" class="position-absolute right-0 top-0"></mat-icon>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="jp-filter-time__to" *ngIf="displayToRange">
                      <div class="width-40">
                        to
                      </div>
                     
                      <div class="width-85 m-l-0 cursor-pointer">

                        <div class="time-row inline-block m-l-xs" [ngClass]="(!startOrEndTime || disableTimeSelection || disableTimeSelectionCheckBox) ? 'disabled' : '' ">
                          <mat-form-field appearance="legacy" class="time_range position-relative" (click)="displayToTimePicker = !displayToTimePicker"
                            [floatLabel]="'never'">
                            <!-- <mat-label>Legacy form field</mat-label> -->
                            <input matInput [value]="selectedEndTime  | dateFormat :'missedAddedTime'">
                            <mat-icon svgIcon="timeTable" class="position-absolute right-0 top-0"></mat-icon>
                          </mat-form-field>
                          <time-picker [dateTime]="selectedEndTime" *ngIf="displayToTimePicker" (cancel)="displayToTimePicker=false" (update)="updateToTime($event)"
                          class="position-relative top-25 to_time_range-panel"></time-picker>
                        </div>
                      </div>
                      
                    </div>

                  </div>
                </div>

                <div class="m-t-xs m-b-xs m-l-xl position-relative m-t-0" *ngIf="startOrEndTime === 'startAndEnd'">
                  <p class="jp-filter-time__type inline-block m-t-xs m-l-xs font-size-16">and</p>
                  <div class="jp-filter-time__type inline-block ">
                    <div class="width-40 inline-block font-size-16">
                      <dropdown [lablel]="'When'" [value]="selectedAndTimeType" [disable]="!startOrEndTime || disableTimeSelection || disableTimeSelectionCheckBox"
                    [dropdownValues]="startDateRange"
                    (valueChage)="andTimeTypeSelected($event)" class="inline-block width-100" ></dropdown>

                    </div>
                    <time-picker [dateTime]="selectedAndStartTime" (cancel)="displayAndFromTimePicker=false;" (update)="updateAndFromTime($event)"
                      *ngIf="displayAndFromTimePicker" class="position-relative top-25 time_range-panel"></time-picker>

                    
                    <div class="width-45 inline-block m-l-sm cursor-pointer" [ngClass]="(!startOrEndTime || disableTimeSelection || disableTimeSelectionCheckBox) ? 'cursor-not-allowed' : '' ">
                      <div class="time-row inline-block m-l-xs" [ngClass]="(!startOrEndTime || disableTimeSelection || disableTimeSelectionCheckBox) ? 'disabled' : '' ">
                        <mat-form-field appearance="legacy" class="time_range" (click)="displayAndFromTimePicker = !displayAndFromTimePicker">
                          <input matInput [value]="selectedAndStartTime  | dateFormat :'missedAddedTime'">
                          <mat-icon matSuffix>access_time</mat-icon>
                        </mat-form-field>
                      </div>
                    </div>
                    <div class="jp-filter-time__to" *ngIf="displayAndToRange">
                      <div class="width-40">
                        to
                      </div>
                      <div class="width-85 m-l-0 cursor-pointer">

                        <div class="time-row inline-block m-l-xs" [ngClass]="(!startOrEndTime || disableTimeSelection || disableTimeSelectionCheckBox) ? 'disabled' : '' ">
                          <mat-form-field appearance="legacy" class="time_range" (click)="displayAndToTimePicker = !displayAndToTimePicker">
                            <!-- <mat-label>Legacy form field</mat-label> -->
                            <input matInput [value]="selectedAndEndTime  | dateFormat :'missedAddedTime'">
                            <mat-icon matSuffix>access_time</mat-icon>
                          </mat-form-field>
                          <time-picker [dateTime]="selectedAndEndTime" *ngIf="displayAndToTimePicker" (cancel)="displayAndToTimePicker=false" (update)="updateAndToTime($event)"
                      class="position-relative top-25 to_time_range-panel"></time-picker>
                        </div>


                      </div>

                    </div>

                  </div>
                </div>
              </div>
              <span class="filter-action-link jp-cursor-link blue-color font-size-14 text-center" (click)="showFilter()">Cancel</span>
            </div>
          </div>
        </div>

      </div>
      <div [ngClass]="categoriesErrorMessage ? 'position-relative' : ''" *ngIf="showCategoriesSection">
        <div catvalues class="border jp-adhc-search-res" [ngClass]="{'height-2px': categoriesErrorMessage}">
          <table tblock [attr.mode]="tablemode" class="jp-table-results" *ngIf="categories?.length; else displaySpinner">
            <tbody>
              <tr *ngFor="let cat of categories; let i = index" class="jp-rcp-att-item">
                <td class="disable edit checkbox p-t-xs">
                  <div>
                    <span><input type="checkbox" class="m-0" value="{{cat?.id }}" name="attribute" id='{{cat?.name }}-{{ cat?._id }}' [checked]="cat?.checked"
                        (click)="recipientsSelected(cat, $event.target.checked, i)" /> </span>
                  </div>
                </td>
                <td class="disable edit font-arial font-size-16 font-weight-400 p-b-xs">
                  <div>
                    <span class="">{{ cat?.name }} </span>
                  </div>
                  <div>
                    <!-- <span class = "textgreysmall jp-result-detailed"></span> -->
                    <span *ngIf="cat?.dtStart" class="text-gray-light font-size-14">{{ cat?.display }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
          <ng-template #displaySpinner>
            <span *ngIf="categoriesErrorMessage; else displayShowCategoriesErrorMessage">
              <p class="text-center position-absolute m-l-md"> {{ categoriesErrorMessage }} </p>
            </span>
            <ng-template #displayShowCategoriesErrorMessage>
              <mat-spinner [diameter]="50" class="margin-auto"></mat-spinner>
            </ng-template>

          </ng-template>
        </div>
      </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <img src="assets/icons/ad-hoc-message-type.svg" class="scheduled_notification_icon">
            <span class="m-l-sm font-weight-700 font-size-17">Scheduled Notifications</span>
          </ng-template>
          <app-notification-history-landing #notificationHistory></app-notification-history-landing>
        </mat-tab>
    </mat-tab-group>
      <!-- hideRecipientSection ends here -->
    </div>
    <div [hidden]="hideRecipientSection" class="overflow-y-auto">
      <div recipients class="m-t-sm">
        <div class="header3 m-sm font-arial font-weight-700 font-size-20">
          <span class="m-l-md">Recipients ({{ (recipientsCount) }})</span>
          <span (click)="closeRecipients()">
            <img width="25" height="25" src="assets/icons/close-drawer.svg" class="jp-cursor-link pull-right m-r-xs">
          </span>
        </div>

        <div class="border-bottom-grey"></div>
        <div *ngIf="recipients?.length; else displayRecipientsSpinner" class="jp-recipients-table">

          <table>
            <thead class="text-left position-relative cursor-pointer">
              <th class="disable edit checkbox border-bottom-grey"
              [ngClass]="category === 'course' ? 'recipients-name-45' : 'recipients-name-50'">
                <div class="m-b-sm">
                  <span class="text-cyan-blue font-arial font-size-16 flex-center-align">
                <input type="checkbox" [checked]="true" name="checkAll" class="m-r-sm"
                  (click)="checkUnCheckParticipants($event)" />
                  <div (click)="sortRecipients('name')">
                  <span class="font-arial font-size-13 text-shade-gray font-weight-700 m-r-sm">NAME</span>
                  <img [ngClass]="{'active': !isNameAsc}" src="assets/icons/sort-by-down.svg" alt="">
                  </div>
                  </span>
                </div>
              </th>
              <th (click)="sortRecipients('email')" class="disable edit border-bottom-grey">
                <div class="m-b-sm font-arial font-weight-400 flex-center-align">
                  <span class="font-arial font-size-13 text-shade-gray font-weight-700 m-r-sm">EMAIL</span>
                  <img [ngClass]="{'active': !isEmailAsc}" src="assets/icons/sort-by-down.svg" alt="">
                </div>
              </th>
              <th *ngIf="category === 'course'" (click)="sortRecipients('participantType')" class="disable edit border-bottom-grey">
                <div class="m-b-sm font-arial font-weight-400 flex-center-align">
                  <span class="font-arial font-size-13 text-shade-gray font-weight-700 m-r-sm">TYPE</span>
                  <img [ngClass]="{'active': !isTypeAsc}" src="assets/icons/sort-by-down.svg" alt="">
                </div>
              </th>
            </thead>
            <tbody>
              <tr *ngFor="let recipient of recipients | orderBy: {property: columnSelectedForSorting, direction: direction}">
                <td class="disable edit checkbox border-bottom-grey jp-table-td-padding"
                [ngClass]="category === 'course' ? 'recipients-name-45' : 'recipients-name-50'">
                  <div class="">
                    <span class="text-cyan-blue font-arial font-size-16"><input type="checkbox" [checked]="true" class="m-r-sm"
                        value="{{recipient?.participantId }}" name="participant" />{{recipient?.name }}</span>
                  </div>
                </td>
                <td class="disable edit border-bottom-grey jp-table-td-padding">
                  <div class="font-arial font-weight-400">
                    <span>{{recipient?.email }}</span>
                  </div>
                </td>
                <td *ngIf="category === 'course'" class="disable edit border-bottom-grey jp-table-td-padding">
                  <div class="font-arial font-weight-400">
                    <span>{{recipient?.participantType }}</span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

      </div>

      <ng-template #displayRecipientsSpinner>
        <div class="m-t-xxl overflow-hidden">
          <mat-spinner [diameter]="50" class="margin-auto"></mat-spinner>
        </div>
      </ng-template>
    </div>

  </div>

</div>